import React from 'react'
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer>
            <div >
                <div style={{ display: 'flex', paddingTop: '20px', justifyContent: 'space-evenly', width: '80%', height: '180px' }}>

                    <div>
                        <img src={"../images/Newlogoforgreensfs.png"} style={{ height: '50px', weight: '64px' }} alt="logo" />
                        <br />
                        <br />
                        <ul style={{
                            display: 'flex', alignItems: 'center', listStyle: 'none',
                            color: '#212121', fontSize: '14px', fontWeight: '200',
                        }}>
                            <li style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100095241292818" >
                                    <img style={{ background: '#014E64' }} src={"../images/facebook.png"} />
                                </a>
                            </li>
                            <li style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/GreenSfs95038" >
                                    <img style={{ background: '#FFFFFF' }} src={"../images/twitter.png"} />
                                </a>
                            </li>
                            <li style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/greensfs">
                                    <img style={{ background: '#FFFFFF' }} src={"../images/linkedin.png"} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        <h2 style={{ color: '#212121', fontSize: '16px', fontWeight: '600', paddingBottom: '5px' }}>Quick Links</h2>
                        <ul style={{ listStyle: 'none', color: '#3D424D', fontSize: '14px', fontWeight: '200', }}>
                            <li><Link to="/" >Home </Link></li>
                            <li><Link to="/GreenPolyDB" >Products </Link></li>
                            <li><Link to="/Services" >Services </Link></li>
                            <li><Link to="/Mission" >Mission </Link></li>
                        </ul>
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        <h2 style={{ color: '#212121', fontSize: '16px', fontWeight: '600', paddingBottom: '5px' }}>Other Links</h2>
                        <ul style={{ listStyle: 'none', color: '#3D424D', fontSize: '14px', fontWeight: '200', }}>
                            {/*
                        <li>FAQ</li>
                        <li>Terms & Condition</li>
                        <li>Support</li>
                         */}
                        </ul>
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        <h2 style={{ color: '#212121', fontSize: '16px', fontWeight: '600', paddingBottom: '5px' }}>Contact US</h2>
                        <ul style={{ listStyle: 'none', color: '#3D424D', fontSize: '14px', fontWeight: '200', }}>
                            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={"../images/mail.png"} /> contact@greenSFS.com</li>
                        </ul>
                    </div>
                </div>



                <div className="copywrite">
                    <hr style={{ color: '#E3E3E3' }} />
                    Copyright © 2023 Green SFS. All rights reserved.
                </div>
            </div>
        </footer>

    )
}

export default Footer;