
import HomeBox1 from "./HomeBox1";
import HomeBox2 from "./HomeBox2";
import HomeBox3 from "./HomeBox3";
import HomeBox4 from "./HomeBox4";
import HomeBox5 from "./HomeBox5";

import { Button } from '@mui/material';
import '../../css/Home.css';
import { Link } from 'react-router-dom';


const Home = () => {
  return (
    <>
      {/* Here section start */}
      <div className="hero">
        {/*
         <video style={{ position: 'absolute', height: '700px', width: '100%', opacity: '.7'}} src="../images/heroVideo.mp4" loop autoPlay muted></video>
      */}

        <div><img className="hero-image-main" src="../images/Frame1.png" alt="hero" /></div>
        <div><img className="hero-image-vector1" src="../images/Vector1.png" alt="vector1" /></div>

        <div className="herotxt" >
          <h1>A Software Development Company Solutions For Startups</h1>
          <p> AI Solutions, Big Data Solutions and CTO Services</p>
          <Link to="/ContactUs" >
            <Button style={{ position: 'relative', zIndex: '100000', marginTop: '10px', backgroundColor: '#7CFFC6', color: 'black' }}
            >Let's Talk</Button>
          </Link>

        </div>

        <div><img className="hero-image-vector2" src="../images/Group.png" alt="Vector2" /></div>
        <div><img className="hero-image-vector3" src="../images/Polygon1.png" alt="Vector3" /></div>
        <div><img className="hero-image-vector4" src="../images/Polygon2.png" alt="Vector4" /></div>

      </div>

      {/* Here section end */}



      <HomeBox1 />
      <HomeBox2 />
      <HomeBox3 />
      <HomeBox4 />
      <HomeBox5 />

    </>
  );

};

export default Home;


{/*

  <div style={{   height: '405px', width: '100%', }} >
        <div className="products-container">
          <div style={{ height: '22px', width: '100%', }} />
<div style={{ display: 'flex', verticalAlign: 'center' }}>

            <div style={{ left: '10%', height: '340px', width: '50%' }}>
              <div style={{ width: '80%' }}  >
                <img src="../images/Rectangle5.png" alt="Polygon2" />
              </div>
            </div>

            <div style={{ right: '10%', height: '340px', width: '50%' }} >
              <div style={{ width: '40%' }} >
                <h1 style={{ textAlign: 'left' }} >About US</h1>
                <p className="main-p" style={{ textAlign: 'left' }} >
                  
                  
                  Our Distributed Data Engine is a cutting-edge solution designed to revolutionize large-scale data analysis. 
                  With seamless scalability, lightning-fast processing, and real-time insights, it empowers businesses ... 
                  </p>

              </div>

            </div>

           </div>

      </div>

*/}