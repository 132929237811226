
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

export default function ActionAreaCard(props) {
  return (
    <Card sx={{ maxWidth: '95%' }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="72"
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          image={props.image}
          alt="green"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>
            {props.title}
          </Typography>
          <Typography style={{ textAlign: 'left',fontSize: '22px'  }} >
            {props.text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>



  );
}

