
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import BasicCard from "../../components/cards/BasicCard";
import Card from "../../components/cards/ActionAreaCard";
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';

const services = [
  <Card
    image="../images/tune.png"
    text={"Our Data Science Consultancy Services provide expert insights and solutions to extract valuable insights from your data. We offer tailored strategies and cutting-edge techniques to optimize decision-making and drive business growth"}
    title={"Data Science"} />,

  <Card
    image="../images/cloud.png"
    text={"Our specialized consulting services provide end-to-end support for efficient and secure cloud migrations. Maximize the benefits of cloud computing, optimize scalability, and enhance performance while minimizing disruptions to operations. Partner with us to migrate cloud technologies for your business success."}
    title={"Cloud Migrations"} />,

  <Card
    image="../images/bigdata.png"
    text={"Our Big Data Architecture Consulting offers specialized expertise in designing robust and scalable data frameworks. We craft tailored solutions to help you manage and harness the power of large datasets, enabling informed decisions and strategic growth."}
    title={"Big data Architecture"} />,

];

const BigdataSolutions = () => {
  return (
    <>
      <div>

        <div>
          <div style={{ background: 'black', height: '480px', fontSize: '1rem' }} >

            <div className="HomeBoxTestTittle"  >
              <h1>BigData Services</h1>
              <h1 style={{ color: '#7CFFC6' }}>Data Science, Big Data Engineering, and Cloud Migrations</h1>
            </div>
          </div>

          <div style={{ background: '#014E64', height: '270px', fontSize: '1rem' }} >
            <div className="HomeBoxTestdesc"  >
              <p>
              Our consulting business specializes in providing comprehensive expertise in Data Science, Big Data Engineering, 
              and Cloud Migrations. We empower organizations to harness the power of their data through advanced analytics, 
              optimize data infrastructure for scalability, and seamlessly transition to cloud environments for enhanced agility 
              and efficiency.
              </p>
            </div>
          </div>

        </div>



        <div id="BigdataServices" className="BigdataServices">

          <div id="Cloud Migrations" className="servicescontent">
            <div className="productimg">
              <img style={{ width: '360px', height: '360px' }} src="../images/cloudmigration.png" alt="ai-services" />
            </div>

            <div style={{ paddingTop: '50px', paddingBottom: '10px' }}>
              <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Cloud Migrations</h2>
              <p style={{ textAlign: 'left', fontSize: '22px' }}>
                At Green SFS, our Cloud Architect Consulting service is centered around helping businesses navigate the
                complexities of cloud computing with a primary focus on seamless cloud migration.
              </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
                Our team of expert cloud architects works closely with clients to assess their existing infrastructure, understand their
                specific requirements, and develop a robust cloud migration strategy. We provide end-to-end support,
                ensuring a smooth and efficient transition to the cloud while minimizing disruptions to operations.
              </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
                With a deep understanding of leading cloud platforms and best practices, we optimize cloud architectures
                to enhance scalability, security, and cost-effectiveness.</p>
            </div>
          </div>



        </div>

        <div id="BigdataServicesCore" className="BigdataServicesCore">
          <h1 style={{ color: 'white', display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>AI Services</h1>

          <div className="servicescontent" >

            <AliceCarousel
              disableButtonsControls
              disableDotsControls
              responsive={{ "0": { items: 3 } }}
              items={services}
            />
          </div>
        </div>


        <div style={{ paddingTop: '20px', paddingBottom: '20px' }} >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10%', marginRight: '10%', paddingTop: '40px' }}>
            <Link to="/ContactUs" >
              <Button variant="contained"  >{'Discuss My BigData Project'} </Button>
            </Link>
          </div>
        </div>


      </div>

    </>


  )
};

export default BigdataSolutions;