
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


export default function UseCasesCard(props) {
  return (
    <Card sx={{ maxWidth:'95%'  }}>
      <CardActionArea>
       
      <CardContent>
          <Typography gutterBottom style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }} variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography style={{ textAlign: 'left',fontSize: '22px'  }} >
            {props.description}
          </Typography>
        </CardContent>
        
      </CardActionArea>
    </Card>

 
 
  );
}