import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import UseCasesCard from "../../components/cards/UseCasesCard";

const items = [

    <UseCasesCard
        description={"GreenPloyDB is a groundbreaking distributed database engine that revolutionizes the sorting of massive data volumes, reaching petabyte-scale efficiency. With unrivaled speed and scalability, our cutting-edge engine is designed to handle the most challenging data sorting tasks. By seamlessly distributing data across nodes and employing advanced parallel processing, GreenPloyDB achieves lightning-fast sorting, drastically reducing processing times"}
        title={"Ultra-Fast Sorting of Petabytes"} />,

    <UseCasesCard
        description={"GreenPloyDB introduces an innovative in-built vector engine, transforming the creation of embeddings for your Machine Learning (ML) models. With this cutting-edge feature, our distributed database engine empowers developers to generate high-quality embeddings directly within their ML workflows.Our vector engine streamlines the embedding creation process, enabling real-time and accurate predictions for data-intensive applications."}
        title={"In-Built Vector Engine"} />,

    <UseCasesCard
        description={"GreenPloyDB introduces a game-changing solution for ETL (Extract, Transform, Load) processes, enabling lightning-fast incremental updates or deletes on exabytes of data. Experience enhanced efficiency and reduced processing times as GreenPloyDB's distributed architecture distributes the workload across nodes, enabling parallel processing and optimal resource utilization. "}
        title={"Exabyte-Scale ETL"} />,

];


const testimonial_items = [
    <UseCasesCard
        description={"Precision agriculture, powered by the convergence of Artificial Intelligence (AI) and the Internet of Things (IoT), revolutionizes traditional farming practices. This cutting-edge approach leverages AI algorithms and IoT sensors to gather real-time data, analyze crop health, monitor environmental conditions, and optimize resource usage. By providing actionable insights, precision agriculture empowers farmers to make data-driven decisions, enhance crop yields, and minimize waste. The seamless integration of AI and IoT technologies paves the way for sustainable and efficient agricultural practices, transforming the future of farming and ensuring food security for a growing global population. Learn more in our Industry section"}
        title={"Precision Agriculture using AI and IoT"} />,

    <UseCasesCard
        description={"Our integrated retail app brings together the power of e-commerce and point of sale (POS) management into a single, seamless platform. With a user-friendly interface and advanced features, this app enables retailers to efficiently manage their online and in-store operations from one centralized hub. From product listings, inventory tracking, and order processing to customer engagement and payment processing, our app streamlines the retail experience, optimizing sales and customer satisfaction. Embrace the future of retail with our all-in-one solution, and elevate your business with enhanced efficiency, convenience, and growth opportunities."}
        title={"Integrated Retail App for E-Commerce and POS"} />,
];

export default function GreenPolyDBBox3() {
    return (
        <div>
            <div className="GPDBImg">
                <img style={{ width: '404px', height: '321px' }} src="../images/GreenPolyDB-main.png" alt="GreenPolyDB-main" />
            </div>

            <div id="UseCases" className="GreenPolyDBBox3" >
                <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', alignContent: 'center', paddingTop: '20px', paddingBottom: '20px' }} >Use Cases</h1>
                <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                    <div className="products-container">
                        <AliceCarousel
                            disableDotsControls
                            responsive={{ "0": { items: 3 } }}
                            items={items}
                        />
                    </div>

                </div>
            </div>
        </div>

    );

}
