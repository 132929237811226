import React from 'react'

const Careers = () => {
  return (

    <div id="careers" className="careers" >
      <h1 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Careers</h1>
      <br />
      <div className="productcontent">
        <div className="productimg" >
          <img style={{  width: '450px', height: '350px' }} src="../images/menu_us.png" alt="Polygon2" />
        </div>
        <div className="productdesc">
          <p>
            Green SFS is a dynamic and innovative tech company specializing in cutting-edge solutions for distributed systems,
            artificial intelligence, and more. With a passion for addressing complex technical challenges,
            we drive substantial transformations across industries.
          </p>
          <br />
          <p >
            Our internship program provides hands-on experience and skill development for aspiring tech enthusiasts.
            As part of our distributed system engineering team, you'll tackle complex projects and optimize system scalability.
            Join our AI engineering team to lead the development of groundbreaking solutions.
            Visit our Careers Page for current opportunities and embark on a transformative journey of technology excellence with us.
          </p>
          <br />
          <p>
            Contact us at <b>contact@greenSFS.com</b> to explore collaborations or learn more about our projects and services. Let's shape the future together!

          </p>


        </div>
      </div>
    </div>
  )
}

export default Careers