import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ListProductsCard from "../../components/cards/ListProductsCard";

const items = [

  <ListProductsCard
    title={"CTO Services"}
    description={"Through our adaptable CTO as a Service engagement models, we support startups and growing SMEs in attaining technical diligence, ensuring seamless business continuity. We offer essential technical leadership and expertise, bridging the gap during the search for a permanent CTO"} 
    url={"CTOServices"}
    image={"../images/cto-services.png"} />,

  <ListProductsCard
    title={"AI Solutions"}
    description={"With a wealth of experience spanning more than a decade, we specialize in designing an AI platform that oversees the model lifecycle, from training to deployment and ML ops. We are here to assist you in unlocking the potential of machine learning algorithms and AI tools."}
    url={"AISolutions"}
    image={"../images/AI1.png"} />,

  <ListProductsCard
    title={"Big Data Solutions"}
    description={"With over a decade of experience in constructing multi-petabyte big data platforms, our Big Data Solutions are well-equipped to aid your business in areas of Data Management, Data Science, Data Integration, Data Analytics, Data Security and Cloud Migrations"}
    url={"BigdataSolutions"}
    image={"../images/big-data.png"} />,


];


export default function HomeBox1() {
  return (
    <div id="services" className="homebox2" >

      <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', paddingTop: '50px', paddingBottom: '50px' }} >Our Services</h1>
      <div style={{ marginLeft: '10%', marginRight: '10%' ,paddingTop: '10px' }}>
        <AliceCarousel
 
          responsive={{ "0": { items: 1 } , "1024" : {items: 3 } }}
          items={items}
        />
      </div>

    </div>




  );

}
