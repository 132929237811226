import { Routes, Route } from 'react-router-dom';

import Layout from './Layout';
import Home from '../routes/home/Home';

import GreenPolyDB from '../routes/products/GreenPolyDB';

import Services from '../routes/services/Services';

import AISolutions from '../routes/services/AISolutions';
import CTOServices from '../routes/services/CTOServices';
import BigdataSolutions from '../routes/services/BigdataSolutions';

import Industries from '../routes/industries/Industries';
import Retail from '../routes/industries/Retail';
import Agriculture from '../routes/industries/Agriculture';


import Company from '../routes/company/Company';
import Careers from '../routes/company/Careers';
import Mission from '../routes/company/Mission'
import ContactUs from '../routes/company/ContactUs';
import AboutUs from '../routes/company/AboutUs';


import "@fontsource/oswald"; // Defaults to weight 400


const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
         
          <Route path="GreenPolyDB" element={<GreenPolyDB />} />

      
          <Route path="Services" element={<Services />} />
          <Route path="AISolutions" element={<AISolutions />} />
          <Route path="CTOServices" element={<CTOServices />} />
          <Route path="BigdataSolutions" element={<BigdataSolutions />} />

          <Route path="Industries" element={<Industries />} />
          <Route path="Retail" element={<Retail />} />
          <Route path="Agriculture" element={<Agriculture />} />

          <Route path="Company" element={<Company />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="AboutUs" element={<AboutUs />} />
          <Route path="Mission" element={<Mission />} />
          <Route path="Careers" element={<Careers />} />          
          


          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
