import React from 'react'
import '../../css/Industries.css';

const Agriculture = () => {
  return (

    <>
      <div className="heroindus">
        <div><img className="hero-image-indus" src="../images/heroindus.png" alt="vector1" /></div>
        <div className="herotxt" >
          <h1> AI and IoT in Agriculture</h1>
        </div>
        <div><img className="hero-image-vector2" src="../images/Ellipse.png" alt="Vector2" /></div>
      </div>
      <br /><br />
      <div className="productcontent">
        <div className="productdesc">
          <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>IoT and AI revolutionize precision agriculture</h2>
          <p style={{ textAlign: 'left', fontSize: '22px' }}>
            Farming without smart technology is labor-intensive and challenging.
            Farmers lack real-time data which makes it difficult to make informed decisions on irrigation, fertilization, and pest control,
            potentially leading to resource wastage and decreased yields.

          </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>

            Additionally,manual monitoring of soil moisture, weather conditions, and crop health would be time-consuming and less accurate,
            affecting the precision of farming practices. Over the preceding three-year period,
            GreenSFS has diligently pursued the objective of innovating agricultural practices while concurrently striving to
            preserve natural resources.

          </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>

            Our efforts have been primarily focused on conducting experimentation within the Wenatchee,
            WA, USA region's farmlands. The research initiatives have centered on developing smart irrigation systems and
            implementing methodologies for the detection and monitoring of plant health.</p>

        </div>

        <div className="productimg" style={{ paddingTop: '30px', paddingBottom: '20px' }}>
          <img style={{ width: '500px', height: '340px' }} src="../images/iotai.png" alt="iot-services" />
        </div>

      </div>

      <br /><br />

      <div id="SmartIrrigation" className="SmartIrrigation">
        <br />
        <h1 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Smart Irrigation</h1>

        <div id="SmartIrrigationSI" className="sicontent">
          <div className="productimg">
            <img style={{ width: '360px', height: '360px' }} src="../images/agri-iot.png" alt="agri-iot" />
          </div>

          <div className="productdesc">
            <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Agricultural IoT</h2>
            <p style={{ textAlign: 'left', fontSize: '22px' }}>
              The IoT, or the Internet of Things encompasses a network of interconnected objects designed to receive and transfer data
              wirelessly. In Agriculture, the potential applications for the IoT are vast, including wireless underground communication,
              recording humidity/rainfall data, and analyzing soil reports.

            </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
              The agricultural internet of things framework is capable of
              detecting farmland conditions, providing actionable suggestions, and issuing commands to farm machinery. Using real-time
              data and analytics, farmers can remotely control irrigation systems, automate tasks, and precisely apply fertilizers and
              pesticides, thus minimizing environmental impact. </p>
          </div>
        </div>
        <br /><br />

        <div id="SmartIrrigationSI1" className="sicontent">
          <div className="productdesc">
            <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Soil Moisture Sensors</h2>
            <p style={{ textAlign: 'left', fontSize: '22px' }} >
              The expected increase in the global population raises concerns regarding the need to conserve fresh water and ensure
              adequate food production for future generations. In order to minimize water wastage and maximize crop yields, accurate
              soil moisture measurements are crucial.
            </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>

              That being said, the utilization of soil sensors on farms remains limited
              due to the high costs and complexity of the current technology. In the past, we have worked with IoT sensors connected
              to the microcontroller, which measure the soil moisture level and transmit the data to our server. </p>
          </div>
          <div className="productimg">
            <img style={{ width: '360px', height: '360px' }} src="../images/soil-sensors.png" alt="ai-services" />
          </div>
        </div>

        <br /><br />
        <div id="SmartIrrigationSI2" className="sicontent">
          <div className="productimg">
            <img style={{ width: '360px', height: '360px' }} src="../images/iot-conn.png" alt="ai-services" />
          </div>

          <div className="productdesc">
            <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>IOT Connectivity</h2>
            <p style={{ textAlign: 'left', fontSize: '22px' }}>
              Although there are many different network protocols like Bluetooth, WiFi, and Cellular, we have chosen LoRa for its
              longer range and low power consumption. Having a long range is essential for capturing data throughout a farm, while
              its low power capability enables prolonged operation. </p>
          </div>
        </div>
        <br /><br />

        <div id="SmartIrrigationSI3" className="sicontent">
          <div className="productdesc">
            <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Drones and RFID tags Soil Moisture Collection</h2>
            <p style={{ textAlign: 'left', fontSize: '22px' }}>
              Currently, at GreenSFS we have been working towards making smart irrigation more cost-effective and sustainable.
              Employing drones equipped with RFID (Radio Frequency Identification) tags and radar technology represents a
              transformative approach to gather moisture data in various applications. 
              </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
              The integration of RFID tags allows
              for the identification and tracking of specific locations or objects, while radar technology facilitates the
              non-invasive assessment of subsurface moisture content. By harnessing these capabilities, GreenSFS aims to enable
              the agricultural industry to benefit from improved data accuracy, real-time insights, and enhanced decision-making.
              This innovative methodology promises to revolutionize the way moisture data is collected,
              leading to more sustainable and resource-efficient practices in a wide range of sectors </p>
          </div>
          <div className="productimg">
            <img style={{ width: '360px', height: '360px' }} src="../images/iotdrone.png" alt="ai-services" />
          </div>
        </div>


      </div>

      <br />

      <div id="DetectingPlantDiseases" className="DetectingPD">
        <br />
        <h1 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Detecting Plant Diseases</h1>


        <div id="DetectingPlantDiseases1" className="DetectingPDContent">
          <br />
          <p style={{ textAlign: 'left', fontSize: '22px' }}>
            Large-scale agriculture poses challenges in distinguishing between healthy and treatment-requiring plants.
            Drone imaging, geocoding, and artificial intelligence come to the rescue. By capturing plant images and GPS coordinates,
            AI can accurately identify which plants need treatment and which are healthy. This not only helps farmers target
            specific areas for treatment but also proactively prevents disease spread. 
            </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
            We employ cutting-edge technologies
            such as drone imaging, geocoding, and artificial intelligence to help with this issue. By capturing precise
            plant images along with GPS coordinates, our AI models can effectively identify which plants require treatment
            and which are in good health. This not only enables farmers to target specific areas for treatment but also empowers
            them to take proactive measures in preventing the spread of diseases across their fields. </p>
        </div>

        <div id="CTOServicesImg" className="CTOServicesImg" >
          <img style={{ width: '900px', height: '260px', borderRadius: '2%' }} src="../images/detectpd.png" alt="CtoHome" />
        </div>

        <p style={{ textAlign: 'left', fontSize: '22px' }} >
          In partnership with farm owners in the Wenatchee area, our cutting-edge technology has revolutionized the detection of
          plant diseases. By leveraging drone imaging, geocoding, and artificial intelligence, we have successfully provided
          accurate and timely assessments of plant health. Our system captures high-resolution plant images and geolocates them,
          enabling our AI models to swiftly identify areas in need of treatment. 
          </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
          Empowered with this invaluable information,
          farm owners in Wenatchee can now take proactive and targeted measures to combat plant diseases, safeguarding their crops
          and optimizing yields. Our technology has not only enhanced disease detection but has also empowered farm owners to
          make informed decisions, ensuring the health and productivity of their fields. </p>
      </div>



    </>


  );
};

export default Agriculture;
