import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Card from "../../components/cards/ActionAreaCard";
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';


const services = [

    <Card
        image="../images/tune.png"
        text={"Leverage the full potential of Foundational Language Models with our tailored solutions. Our expert consultants fine-tune LLMs to address your specific business needs, optimizing performance and accuracy. Maximize the impact of Foundational Models in driving language-driven success for your business"}
        title={"Fine-tuning LLMs"} />,

    <Card
        image="../images/text.png"
        text={"Our team of NLP specialists delivers tailored solutions to address your unique text-related challenges. Our consulting empowers businesses to leverage NLP for enhanced insights and automated processes. Maximize the impact of NLP technology in unlocking valuable knowledge from text data"}
        title={"Text2NLP"} />,

    <Card
        image="../images/eye.png"
        text={"Our solutions cater to a wide range of industries, applying computer vision in video feeds and image generation. With expertise in image segmentation and detection, we have successfully developed solutions for diverse applications, including assessing the health of fruit orchards in agriculture."}
        title={"Computer Vision Solutions"} />,

];


const AISolutions = () => {
    return (
        <>
            <div>

                <div>
                    <div style={{ background: 'black', height: '480px', fontSize: '1rem' }} >

                        <div className="HomeBoxTestTittle"  >
                            <h1>AI Solutions</h1>
                            <h1 style={{ color: '#7CFFC6' }}>Proficient in NLP, Computer Vision, and Fine-Tuning LLMs</h1>
                        </div>
                    </div>

                    <div style={{ background: '#014E64', height: '270px', fontSize: '1rem' }} >
                        <div className="HomeBoxTestdesc"  >
                            <p>
                            At GreenSFS, we are passionate about harnessing the power of AI to drive transformative solutions for businesses
                             across various industries. Our core focus revolves around model and algorithm building, 
                             where we design and develop large scale platforms that can analyze complex data, model traning & deployment, 
                             and automate processes with ML ops.                           
                            </p>
                        </div>
                    </div>

                </div>


                <div id="AISolutions" className="AISolutions">

                    <br />
                    <h1 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', fontSize: '36px', paddingTop: '20px', paddingBottom: '10px' }}>Our AI Focus Areas</h1>

                    <br />
                    <div id="AI" className="servicescontent">
                        <div className="productimg">
                            <img style={{ width: '360px', height: '360px' }} src="../images/ai-services.png" alt="ai-services" />
                        </div>

                        <div style={{ paddingTop: '50px', paddingBottom: '10px' }}>
                            <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }} >Artificial Intelligence</h2>
                            <p style={{ textAlign: 'left', fontSize: '22px' }}>
                                Experience our comprehensive AI consulting services, encompassing model building, fine-tuning, and deployment,
                                all finely tuned to suit your specific business needs.

                            </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
                                Our expertise includes industry-specific guidance, ensuring
                                seamless AI integration within your domain to unleash transformative innovation and unprecedented growth.
                            </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
                                Embrace the power of AI to optimize operations, boost efficiency, and stay ahead in your industry.
                                With our tailored approach and industry know-how, and achieving remarkable outcomes. Unlock endless possibilities with our unparalleled
                                AI consulting services. </p>
                        </div>
                    </div>
                    <br /><br />
                    <div id="LLMs" className="servicescontent">

                        <div style={{ paddingTop: '50px', paddingBottom: '10px' }} >
                            <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }} >Generative AI</h2>
                            <p style={{ textAlign: 'left', fontSize: '22px' }}>
                                As part of our AI consulting services, we offer custom fine-tuned Language Models (LLMs) tailored to the unique needs of
                                individual businesses.
                            </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
                                While pre-trained models have gained popularity, a personalized LLM trained on specific datasets relevant to the business
                                provides accurate language understanding and enhanced performance. Our consulting service empowers businesses to leverage
                                the power of pre-trained models while ensuring a precise fit to their data and requirements.
                            </p><br /><p style={{ textAlign: 'left', fontSize: '22px' }}>
                                This approach enhances data privacy, provides a competitive edge, and enables seamless integration
                                into various NLP tasks within the business domain.           </p>
                        </div>

                        <div className="serviceimg">
                            <img style={{ width: '360px', height: '360px' }} src="../images/generative-ai.png" alt="ai-services" />
                        </div>
                    </div>
                    <br /><br />


                </div>
            </div>


            <div id="AIServices" className="AIServices">
                <br />
                <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', alignContent: 'center', paddingTop: '20px', paddingBottom: '20px' }}>AI Services</h1>

                <div className="servicescontent" >

                    <AliceCarousel
                        disableButtonsControls
                        disableDotsControls
                        responsive={{ "0": { items: 3 } }}
                        items={services}
                    />
                </div>
            </div>

            <div id="principles" className="principles">
                <br />
                <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', alignContent: 'center', paddingTop: '20px', paddingBottom: '20px' }}>Our Consulting Principles</h1>

                <div className="servicescontent" >
                    <div style={{ display: 'inline', float: 'left' }}>
                        <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Client-Centric Approach</h2>
                        <ol style={{ listStyle: 'none' }}>
                            <li>We prioritize understanding our clients' unique challenges and objectives, tailoring our solutions to meet their specific needs and goals.</li>
                        </ol>
                        <br />

                        <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Expertise and Innovation</h2>
                        <ol style={{ listStyle: 'none' }}>
                            <li>Our team of seasoned professionals stays at the forefront of AI and ML advancements, offering cutting-edge solutions and innovative approaches.</li>
                        </ol>
                        <br />

                        <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Ethical and Responsible AI</h2>
                        <ol style={{ listStyle: 'none' }}>
                            <li>We uphold the highest ethical standards, ensuring that AI implementations align with responsible practices and safeguard data privacy</li>
                        </ol>
                        <br />

                        <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Transparent Communication</h2>
                        <ol style={{ listStyle: 'none' }}>
                            <li>We maintain open and transparent communication with our clients, fostering collaboration and providing regular updates throughout the consulting process.</li>
                        </ol>
                        <br />

                        <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }}>Sustainable Value</h2>
                        <ol style={{ listStyle: 'none' }}>
                            <li>Our goal is to deliver sustainable value to our clients, empowering them with AI solutions that drive long-term growth, efficiency, and success</li>
                        </ol>
                        <br />

                    </div>

                </div>
            </div>


            <div id="techStack" className="techStack">
                <br />
                <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', alignContent: 'center', paddingTop: '20px', paddingBottom: '20px' }}>Our Tech Stack</h1>

                <div className="servicescontent" >
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                        <div style={{ paddingRight: '100px' }}><img style={{ width: '72px', height: '72px' }} src="../images/aws.png" alt="aws" /></div>
                        <div style={{ paddingRight: '100px' }}><img style={{ width: '72px', height: '72px' }} src="../images/gcp.png" alt="gcp" /></div>
                        <div style={{ paddingRight: '100px' }}><img style={{ width: '72px', height: '72px' }} src="../images/azure.png" alt="azure" /></div>
                        <div style={{ paddingRight: '100px' }}><img style={{ width: '200px', height: '92px' }} src="../images/spark.png" alt="gcp" /></div>
                        <div style={{ paddingRight: '100px' }}><img style={{ width: '200px', height: '92px' }} src="../images/scikit-learn.png" alt="azure" /></div>
                        <div style={{ paddingRight: '100px' }}><img style={{ width: '200px', height: '92px' }} src="../images/pytorch.png" alt="azure" /></div>

                    </div>

                </div>

                <div style={{ paddingTop: '20px', paddingBottom: '20px' }} >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10%', marginRight: '10%', paddingTop: '40px' }}>
                        <Link to="/ContactUs" >
                            <Button variant="contained"  >{'Discuss My AI Project'} </Button>
                        </Link>
                    </div>
                </div>
            </div>


        </>
    );
};

export default AISolutions;
