import * as React from 'react';
import { Button, Card, CardMedia, CardContent, Typography, CardActionArea } from '@mui/material'

export default function BasicCard(props) {
    return (
        <Card >
      <CardActionArea style={{display:'flex', alignItems:'flex-start'}}>
        <CardMedia style={{ width: '30%'}}
          component="img"
          height="72px"
          weight="72px"
          sx={{ paddingTop:'10px', objectFit: "contain" }}
          image={props.image}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }} variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography style={{ textAlign: 'left',fontSize: '22px'  }} >
            {props.description}
          </Typography>
        </CardContent>
    
      </CardActionArea>
    </Card>
    );
}