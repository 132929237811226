import React from 'react';

const AboutUs = () => {
  return (
    <div  className="AboutUs">
      <h1 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>About Us</h1>
      <div className="productcontent">
        <div className="productimg">
          <img style={{ width: '500px', height: '350px' }} src="../images/aboutus.png" alt="Polygon2" />
        </div>
        <div className="productdesc">
          <p >
            We are an ambitious startup comprising a dynamic team of Engineers, Scientists, and Researchers who share a profound belief in the potential of groundbreaking algorithms and platforms to address significant technical challenges in the coming decade. Our core focus lies in innovation, as we strive to create cutting-edge solutions that will drive substantial transformations across various industries.
          </p>
          <br />
          <p>
            In addition to our research endeavors, we offer specialized consulting services to companies seeking to develop robust and scalable solutions in the realms of big data, artificial intelligence, and cloud computing. Our aim is to empower businesses with the knowledge and expertise needed to harness the full potential of these advanced technologies.
          </p>
          <br />
          <p>
            At our core, we are driven by a passion for excellence and a commitment to revolutionizing the future of technology through innovation and collaboration.
          </p>
        </div>
      </div>
    </div>


  )
}

export default AboutUs;