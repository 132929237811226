import Navbar from './Navbar';
// ...
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <div className="navbar">
        <div className="logo" >
          <Link to="/" >
            <img src={"../images/Newlogoforgreensfs.png"} alt="logo" />
          </Link>
         
        </div>

        <Navbar />
         

        {/*}
        <div style={{ gap: '8px', position: 'absolute', padding: '12px 24px 12px 24px', right: '48px', top: '5px', height: '48px', width: '110px' }}>
          <Link to="/" >
            <img src={"../images/Frame3.png"} alt="contact" />
          </Link>
        </div>
       */}

      </div>
    </header>
  );
};

export default Header;
