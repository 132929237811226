export const menuItems = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Products',
    url: '/GreenPolyDB',
    submenu: [
      {
        title: 'GreenPolyDB',
        url: '/GreenPolyDB',
      },
     
     
    ],
  },
  {
    title: 'Services',
    url: '/Services',
    submenu: [
      {
        title: 'CTO Services',
        url: '/CTOServices',
        
      },
      {
        title: 'AI Solutions',
        url: '/AISolutions',
        
      },      {
        title: 'BigData Solutions',
        url: '/BigdataSolutions',
        
      },
    ],
  },
  {
    title: 'Industries',
    url: '/Agriculture',
    submenu: [
      {
        title: 'Agriculture',
        url: '/Agriculture',
      },
      
    ],
  },
  {
    title: 'Company',
    url: '/Company',
    submenu: [
      {
        title: 'Mission',
        url: '/Mission',
      },
      {
        title: 'Careers',
        url: '/Careers',
      },
      {
        title: 'About Us',
        url: '/AboutUs',
      },
      {
        title: 'Contact Us',
        url: '/ContactUs',
      },
    ],
  },
];
