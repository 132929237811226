import React from 'react'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import BasicCard from "../../components/cards/BasicCard";

const items1 = [
    <BasicCard
        title={"Working Backwards"}
        description={"Our fundamental value centers around prioritizing outcome-driven methods that commence with a defined end goal, effectively reducing risk and ambiguity. This approach emphasizes customer focus from day one, ensuring our efforts are delivering value."}
        image={"../images/workingbackwards.png"} />,

    <BasicCard
        title={"Continuous Learning"}
        description={"In our rapidly evolving technological landscape, we embrace the swift pace of change. We are constantly researching the next era products, enabling us to offer services that empower clients to harness the benefits of these advancements,cost savings,and growth"}
        image={"../images/cl.png"} />,


];

const items2 = [
    <BasicCard
        title={"Cost in Mind"}
        description={"We firmly believe that technology expenses shouldn't encumber your core business operations. Hence, we offer cost-effective solutions tailored to your business's current state, fostering efficient growth and streamlined operations."}
        image={"../images/cost.png"} />,
    <BasicCard
        title={"Scale of Innovation"}
        description={"Our emphasis lies in crafting innovative scaling solutions with elasticity. As your business grows, there's no need to re-architect your systems; simply add infrastructure with auto scaling. Scaling involves expanding your system's functionality."}
        image={"../images/scale.png"} />

];




const HomeBox4 = () => {
    return (

        <div id="AI" className="homebox4">

            <h1 style={{ display: 'flex',  fontSize: '36px', justifyContent: 'center', paddingTop: '50px', paddingBottom: '30px' }} >Our Values </h1>

            <p style={{ color: 'white', display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}></p>
            <div style={{ marginLeft: '10%', marginRight: '10%', paddingBottom: '10px', paddingTop: '10px' }}>

                <AliceCarousel

                    responsive={{ "0": { items: 1 } , "1024" : {items: 2 } }}
                    items={items1}
                />
                <br />

                <AliceCarousel

                    responsive={{ "0": { items: 1 } , "1024" : {items: 2 } }}
                    items={items2}
                />

            </div>

        </div>




    )
}

export default HomeBox4