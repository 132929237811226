
import { Button, Card, CardMedia, CardContent, Typography, CardActionArea } from '@mui/material'
import { Link } from 'react-router-dom';

export default function ListProductsCard(props) {
  return (
    <Card sx={{ maxWidth: '99%' }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="92px"
          weight="92px"
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          image={props.image}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom style={{ color: '#014E64', fontWeight: '600', fontSize: '28px' }} variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography style={{ textAlign: 'left',fontSize: '22px'  }} >
            {props.description}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          <Link to={props.url} >
            <Button variant="contained" >Learn More</Button>
            </Link>
          </Typography>
        </CardContent>

      </CardActionArea>
    </Card>



  );
}
