import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';

export default function HomeBox3() {
  return (

    <div>
      <img style={{ position: 'absolute', paddingTop: '50px' }} src="../images/productbg.png" alt="Polygon2" />
      <img style={{ position: 'absolute', width: '535px', top: '2470px', height: '232px', right: '0%' }} src="../images/Vector2.png" alt="Polygon2" />
      <div id="products" className="homebox1">

        <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', alignContent: 'center', paddingTop: '50px', paddingBottom: '50px' }}>Our Products</h1>

        <div className="productcontent">

          <div className="productimg">
            <img style={{ width: '420px', height: '375px' }} src="../images/PolyDB_s.png" alt="Polygon2" />
          </div>

          <div className="productdesc">

            <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '28px',paddingBottom: '20px' }}>GreenPolyDB</h2>


            <p >
              Our Distributed Data Engine is a cutting-edge solution designed for scale data analysis at the exabyte level,
              poised to revolutionize large-scale information processing.
            </p> <p >
              With seamless scalability, lightning-fast processing, and real-time insights, embrace cost-effective and efficient data analysis,
              uncovering valuable insights that drive your business forward.
            </p> <p >
              Elevate your data capabilities and explore the future of scale data analysis at the exabyte level
              with our Distributed Data Engine today!
            </p>

            <div style={{ paddingTop: '20px' }} >
              <Link to="/GreenPolyDB" >
                <Button variant="contained" >Learn more</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

  );

}
