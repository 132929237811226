import React from 'react'
import '../../css/Company.css';
import Mission from './Mission';
import Careers from './Careers';

import AboutUs from './AboutUs';
import ContactUs from './ContactUs';

const Company = () => {
  return (


    <div>
      {/* Here section start */}
      <div className="heroabout">

        <div><img style={{ marginTop: '1px', height: '400px', width: '100%' }} src="../images/herocontactus.png" alt="vector1" /></div>
        <div><img className="hero-image-vector1" src="../images/Ellipse.png" alt="vector1" /></div>

        <div className="herotxt" >
          <h1>  Green SFS Mission & </h1>
          <h1>  Careers</h1>
        </div>
        <div><img className="hero-image-vector2" src="../images/Ellipse.png" alt="Vector2" /></div>
      </div>

      {/* Here section end */}
     
      <Mission />

      <Careers />

      <AboutUs />

      <ContactUs />
    </div>

  )
}

export default Company