import React, { useState } from "react";
import { menuItems } from '../menuItems';
import MenuItems from './MenuItems';


const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
   
    <div className="menubar">
      <div className="burgermenu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul className={menuOpen ? "open" : "menus" }>
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
            />
          );
        })}
      </ul>
    </div>
     
  );
};

export default Navbar;
