import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";

export default function GreenPolyDBBox5() {
    return (
        <div id="GettingStarted" className="GreenPolyDBBox5" >
            <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', alignContent: 'center', paddingTop: '20px', paddingBottom: '20px' }} >Getting Started</h1>
            <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                <div className="products-container">
                    <br />
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '50%' }}>
                            <h2 style={{ fontWeight: '600', fontSize: '28px' }}>Docker</h2>
                            <p>Start with Docker Container Image(Coming Soon)</p>
                            <br />
                            <h2 style={{ fontWeight: '600', fontSize: '28px' }}>AWS Image</h2>
                            <p>Start with AWS Image on the Cloud(Coming Soon)</p>
                            <br />
                            <h2 style={{ fontWeight: '600', fontSize: '28px' }}>Github </h2>
                            <p>Are you developer, you would like to contribute, source code : Github(Coming Soon)</p>
                        </div>
                        <div>
                            <h2 style={{ fontWeight: '600', fontSize: '28px' }}>Documentation</h2>
                            <p>User docs(Coming Soon)</p>
                            <p>Development docs(Coming Soon)</p>
                            <br />
                            <h2 style={{ fontWeight: '600', fontSize: '28px' }}>Blog</h2>
                            <p>Developers Blog (Coming Soon)</p>
                            <br />
                            <h2 style={{ fontWeight: '600', fontSize: '28px' }}>Request Private Demo</h2>
                            <p> please send a request to contact@greensfs.com for a private demo.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
