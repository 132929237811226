import { Link } from 'react-router-dom';
import '../../css/Services.css';

import AISolutions from "./AISolutions";
import BigdataSolutions from "./BigdataSolutions";
import CTOServices from "./CTOServices";

const Services = () => {
  return (
    <>
      <CTOServices />
      <AISolutions />
      <BigdataSolutions />


    </>
  );


};

export default Services;
