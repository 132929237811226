import React from 'react'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';

const HomeBox5 = () => {
    return (
        <div className='homebox5' >
            <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10%', marginRight: '10%',
                paddingTop: '10px'
            }}>
                <Link to="/ContactUs" >
                    <Button variant="contained" >{'Discuss My Project'} </Button>
                </Link>
            </div>
        </div>
    )
}

export default HomeBox5