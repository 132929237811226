import React from 'react'

const Mission = () => {
    return (
        <div id="mission" className="mission">
            <h1 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>Mission</h1>
            <div className="productcontent">
                <div className="productimg" >

                    <img style={{ width: '493px', height: '450px' }} src="../images/mission.png" alt="Polygon2" />

                </div>
                <div className="productdesc">
                    <p >
                        At Green SFS, our passionate startup is driven by pioneering algorithms and
                        platforms to tackle upcoming challenges.
                        Our focus lies in innovating solutions that lead an industry revolution.
                        We take a dual approach.
                    </p>
                    <br />
                    <p>
                        Firstly, we provide specialized consulting, empowering companies in creating
                        strong solutions in big data, AI, and cloud computing. Our mission is to equip
                        businesses with the expertise to
                        fully utilize these technologies, thriving in a rapidly evolving tech landscape.
                    </p>
                    <br />
                    <p>
                        Secondly, our dedicated team of Engineers, Scientists, and Researchers is the
                        cornerstone of our efforts.
                        Fueled by a passion for excellence and collaborative innovation,
                        we're poised to drive transformative changes across industries,
                        striving for significant shifts and global technology adoption.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default Mission