import React from 'react'


const ContactUs = () => {
  return (

    <div id="ContactUs" className="ContactUs">
      <div className="productdesc" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <h1 style={{ paddingBottom: '20px' }}>Contact Us</h1>
        <p >  Thank you for your interest in Green SFS!
          Whether you have a data-related project in mind or would like to meet our talented team,
          we are eager to connect with you. </p>
        <br />
        <p >
          Please feel free to reach out to us at <b> contact@greenSFS.com</b>,
        </p>
        <br />
        <p >
          Our dedicated representatives will be delighted to assist you.
          We look forward to exploring opportunities for collaboration and delivering innovative data solutions
          tailored to your unique needs.
        </p>

      </div>

      <div >
        <img src="../images/mailus.png" height="330px" width="400px" alt="home" />

      </div>
    </div>

  )
}

export default ContactUs