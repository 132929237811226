import React from 'react'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';
import { green } from '@mui/material/colors';

const homebox2 = () => {
    return (
        <div>
            <div style={{ background: 'black', height: '480px', fontSize: '1rem' }} >

                <div className="HomeBoxTestTittle"  >
                    <h1>Revolutionizing Large data Management</h1>
                    <h1 style={{ color: '#7CFFC6' }}>With AI Solutions</h1>
                </div>
            </div>

            <div style={{ background: '#014E64', height: '270px', fontSize: '1rem' }} >
                <div className="HomeBoxTestdesc"  >
                    <p>
                        Are you tired of dealing with rigid database solutions that limit your
                        ability to scale and adapt to changing data needs? Look no further! Our
                        Distributed Database Engine is a game-changer, designed to offer
                        unprecedented flexibility, speed, and scalability for your data-driven
                        applications.
                    </p>
                </div>
            </div>

        </div>


    )
}



export default homebox2