import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import BasicCard from "../../components/cards/BasicCard";
import Card from "../../components/cards/ActionAreaCard";
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';

const services = [
  <Card
    image="../images/virtual-cto.png"
    text={"Our Virtual CTO consulting service offers startups and growing businesses the expertise of a seasoned CTO on a remote basis. We provide strategic technology guidance, leadership, and support to drive your company's technical success"}
    title={"CTO consulting"} />,

  <Card
    image="../images/interim.png"
    text={"Our Interim CTO consulting service provides temporary executive-level technology leadership for your organization. Experienced professionals step in to guide your tech initiatives and maintain operational continuity during transitional phases."}
    title={"Interim CTO"} />,

];


const items1 = [
  <BasicCard
    title={"Planning"}
    description={"Our CTO offering for strategy planning encompasses people, processes, and technology to enhance your business plan. We assist in projecting and executing a roadmap for achieving your goals."}
    image={"../images/planning.png"} />,

  <BasicCard
    title={"Technology"}
    description={"Our CTO offering empowers you to develop technical solutions and architecture planning for short, mid, and long-term goals. We provide guidance and solutions to ensure your technical strategies align with your business objectives"}
    image={"../images/tech.png"} />,

  <BasicCard
    title={"Scale"}
    description={"Our CTO offering for scalability equips your business with expert strategies and solutions to expand seamlessly. We enable you to grow efficiently, meeting evolving demands and ensuring sustainable success."}
    image={"../images/scalable.png"} />,
  <BasicCard
    title={"Operational Excellence"}
    description={"Our CTO offering for operational excellence empowers your business with tailored strategies and insights. We optimize processes and technologies, enhancing efficiency and driving overall performance."}
    image={"../images/ops-excellence.png"} />

];



const CTOServices = () => {
  return (
    <>

      {/* Here section start */}
      <div className="hero-services">

        <div className="hero-services-text" >
          <h1>CTO, AI and Big Data Services</h1>
        </div>
      </div>

      {/* Here section end */}

      <br />
      <br />

      <div>
        <div id="CTOServices" className="CTOServices">
          <br />
          <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', alignContent: 'center', paddingTop: '20px', paddingBottom: '20px' }}>CTO Services</h1>

          <div className="servicescontent" >
            <p style={{ textAlign: 'left', fontSize: '22px', paddingBottom: '20px' }}>
              Our Virtual CTO services primarily cater to startups, assisting them in achieving technical excellence through adaptable CTO-as-a-Service engagement models. We can help you fill the early-stage technological leadership gap and provide ongoing support as a virtual CTO.
            </p>
          </div>

        </div>


        <div id="CTOServicesImg" className="CTOServicesImg" >
          <img style={{ width: '600px', height: '300px', borderRadius: '2%' }} src="../images/CtoHome.png" alt="CtoHome" />
        </div>


        <div id="CTOServicesCore" className="CTOServicesCore">
          <h1 style={{ color: 'white', display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>AI Services</h1>

          <div className="servicescontent" >

            <AliceCarousel
              disableButtonsControls
              disableDotsControls
              responsive={{ "0": { items: 3 } }}
              items={services}
            />
          </div>
        </div>

        <div id="CTOServicesItem" className="CTOServicesItem">

          <h1 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}> CTO As A Service </h1>

          <div className="servicescontent" >

            <AliceCarousel
              disableButtonsControls
              disableDotsControls
              responsive={{ "0": { items: 3 } }}
              items={items1}
            />

          </div>
        </div>

        <div className='homebox5' >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10%', marginRight: '10%', paddingTop: '40px' }}>
            <Link to="/ContactUs" >
              <Button variant="contained" >{'Get CTO Services'} </Button>
            </Link>
          </div>
        </div>


      </div>
    </>


  )
};

export default CTOServices;