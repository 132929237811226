import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ActionAreaCard from "../../components/cards/ActionAreaCard";

const items1 = [
  <ActionAreaCard title={"Plugable with Any RDBMS "}
    image={"../images/plug.png"}
    text={"Our Distributed Database Engine provides a unique and seamless integration with any Relational Database Management System (RDBMS). Whether you're already using MySQL, PostgreSQL, Oracle, or any other RDBMS, our engine effortlessly integrates with your existing setup, eliminating the need for costly and time-consuming data migrations"} />,

  <ActionAreaCard title={"Faster than NoSQL Databases"}
    image={"../images/sql.png"}
    text={"Experience lightning-fast performance without compromising on data integrity. Our Distributed Database Engine is carefully engineered to deliver superior speed and efficiency, surpassing even the most popular NoSQL databases in speed benchmarks. Say goodbye to slow queries and time-consuming data retrievals."}
  />,

  <ActionAreaCard title={"Effortless Scalability"}
    image={"../images/scale.png"}
    text={"We understand that your data needs may grow rapidly, and our engine is built to scale effortlessly. Whether you're managing a small startup or a large enterprise, our distributed architecture ensures that your database system grows with your business, accommodating increased data volume and traffic without compromising on performance."}
  />,
];

const items2 = [
  <ActionAreaCard title={"Easy to Change Structured Data"}
    image={"../images/change.png"}
    text={"Business requirements change, and so should your database. With our Distributed Database Engine, making structural changes to your database becomes a breeze. Say farewell to the days of complex schema alterations, as our engine allows you to adapt your data structures seamlessly, saving valuable time and effort."}
  />,

  <ActionAreaCard title={"Hybrid Architecture Cloud-Native "}
    image={"../images/cloud.png"}
    text={"Embrace the power of a hybrid architecture that blends the best of on-premises and cloud environments. Our Distributed Database Engine is cloud-native, enabling you to harness the benefits of cloud computing while ensuring optimal data accessibility, security, and compliance. Enjoy the flexibility of deploying our database engine across public, private, or hybrid cloud infrastructures."}
  />,
  <ActionAreaCard title={"Open Source"}
    image={"../images/opensource.png"}
    text={"We believe in collaboration and the power of the community. Our Distributed Database Engine is proudly open-source, inviting developers and data enthusiasts to contribute, innovate, and customize the solution to suit their specific needs. Benefit from continuous updates, enhancements, and community-driven support, all while enjoying the freedom of an open-source database engine."}
  />,
];


export default function GreenPolyDBBox2() {
  return (
    <div id="keyFeatures" className="GreenPolyDBBox2" >
      <h1 style={{ display: 'flex', fontSize: '36px', justifyContent: 'center', alignContent: 'center', paddingTop: '20px', paddingBottom: '20px' }} >Key Features</h1>
      <div style={{ marginLeft: '10%', marginRight: '10%' }}>
        <AliceCarousel
          disableButtonsControls
          disableDotsControls
          responsive={{ "0": { items: 3 } }}
          items={items1}
        />
        <br />
        <AliceCarousel
          disableButtonsControls
          disableDotsControls
          responsive={{ "0": { items: 3 } }}
          items={items2}
        />

      </div>

    </div>

  );

}
