
import '../../css/Products.css';
import GreenPolyDBBox1 from "./GreenPolyDBBox1";
import GreenPolyDBBox2 from "./GreenPolyDBBox2";
import GreenPolyDBBox3 from "./GreenPolyDBBox3";
import GreenPolyDBBox4 from "./GreenPolyDBBox4";
import GreenPolyDBBox5 from "./GreenPolyDBBox5";

const GreenPolyDB = () => {

  return (
    <>
      {/* Here section start */}

      <div className="hero-product ">
       
        <div className="hero-product-text" >
          <h1 style={{ textAlign: 'left', fontSize: '62px' }}>  GreenPolyDB</h1>
        </div>

        <div className='hero-product-img'><img style={{ marginTop: '1px', height: '390px', width: '450px' }} src="../images/GreenPolyDB.png" alt="vector1" /></div>
      </div>

      {/* Here section end */}

      <GreenPolyDBBox1 />
      <GreenPolyDBBox2 />
      <GreenPolyDBBox3 />

      {/** 
      <GreenPolyDBBox4 />
    **/}
      <GreenPolyDBBox5 />
    </>
  );
};

export default GreenPolyDB;