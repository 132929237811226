import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { Button } from '@mui/material'


export default function GreenPolyDBBox1() {
  return (

    <div>

      <div className="GPDBcontent">

        <div className="GPDBDesc">
          <h2 style={{ color: '#014E64', fontWeight: '600', fontSize: '32px' }}>Introducing our Revolutionary Distributed Database Engine!</h2>
          <p style={{ textAlign: 'left', fontSize: '22px' }}>
            Our Distributed Data Engine is a cutting-edge solution designed to revolutionize large-scale data analysis.
            With seamless scalability, lightning-fast processing, and real-time insights,
            it empowers businesses to make data-driven decisions with confidence. The fault-tolerant architecture ensures data integrity
            and uninterrupted operations, while intuitive data visualization enhances data exploration.
            Embrace cost-effective and efficient data analysis, uncovering valuable insights that drive your business forward.
            Elevate your data capabilities and explore the future of large-scale data analysis with our Distributed Data Engine today!            </p>
        </div>
      </div>
    </div>


  );

}
